$(document).ready(function(){
  getHomeTrendingNow();
})
function getHomeTrendingNow(){
  actionWebApiRestrict('/v1/stream/article/trending', {'limit':5}, 'GET').done(function(json) {
    if (json.status == 600) {
      let stream = json.data;
      let keynumber = 1;
      var appendText = '';
      $.each(stream, function(key, value) {
        appendText += drawHomeTrendingNow(value, keynumber,'Terpopuler','Home Page');
        keynumber ++;
      })
      $('#populer-list').append(appendText)
    }
  })
}
function drawHomeTrendingNow(stream,number,item,position){
  var lengthTitle = stream.title.length;
  var setTitle = ''
  if(lengthTitle > 100){ 
    setTitle = htmlSubstring(stream.richtext_title,100)+'...'
  }else{
    setTitle = stream.richtext_title
  }
  var append = '';
  append += '<div class="populer-items-container">';
  append += '  <a href="'+stream.url+'" title="'+stream.title+'" class="populer-items-detail" onclick="panelTrackingPushSection(\''+item+'\',\''+stream.url+'\',\''+position+'\',\''+stream.title+'\')">';
  if(item == 'Terpopuler') append += '    <span class="populer-number">'+number+'</span>';
  append += '    <span class="populer-title">'
  if(typeof stream.cover !== "undefined"){
    append += '    <span class="populer-title"><span class="populer-thumb"><img src="'+stream.cover.small+'" alt="cover populer"></span>';
  }
  append += '       <span class="populer-title-content list-content-'+item+'"><h4>'+setTitle+'</h4></span>';
  append += '   </span>';
  append += '  </a>';
  append += '</div>';
  return append;
}

var panelTrackingPushSection = function(item, target, position, title) {
  dataLayer.push({
    'event': 'paneltracking',
    'panelname': item,
    'panelposition': position,
    'panelpage_url': target,
    'panelpage_title': title
  });
}